<template>
  <div class="LiveRooms">
    <div class="liveRntroduce">
      <div>
        <img style="max-height: 180px" :src="liveContent.coverUrl" alt="">
      </div>
      <div class="topRight">
        <div style="display: flex">
          <h3 class="title">{{liveContent.title}}</h3>
          <a-tag class="tag" color="#2db7f5">{{liveContent.streamStatus === 0 ? '未开始':liveContent.streamStatus === 1 ? '直播中': liveContent.streamStatus === 2 ? '已结束': ''}}</a-tag>
        </div>
        <div style="display: flex">
          <div class="content">
            <div class="font">分类： {{liveContent.liveType}}</div>
            <div class="font">会议时间： {{liveContent.showTime}}</div>
            <div class="font">开始时间： {{liveContent.live_start_time}}</div>
          </div>
          <div  class="content">
            <div class="font">创建人： {{liveContent.createdName}}</div>
            <div class="font">创建时间： {{liveContent.createdTime}}</div>
<!--            <div class="font">直播客服： <a @click="openComment">韩笑</a></div>-->
            <div class="font">直播客服： <a>韩笑</a></div>
          </div>
        </div>
      </div>
      <div class="topBtn">
        <div @click="returnBtn"><a><a-icon type="left" />返回直播列表</a></div>
        <div style="margin-top: 100px">
          <a-button shape="round" @click="openCode">栏目排序</a-button>
          <a-button style="margin-left: 10px;margin-right: 10px" shape="round" @click="editLive(liveContent)">编辑会议</a-button>
          <a-button type="primary" shape="round" @click="openLink">直播链接</a-button>
        </div>
      </div>
    </div>
    <div class="liveRntroduce-content">
      <div style="width: 270px">
        <div v-for="(list,index) in pathList" :key="list.id">
          <div class="menu">
            <span style="margin-left: 5%">{{list.title}}</span>
            <a v-show="index === 0" class="add" @click="openFunModel('新增')">+</a>
          </div>
          <a-menu
              style="border-right: none"
              v-model="selectedKeys"
              mode="inline"
              :inline-collapsed="collapsed">
            <a-menu-item
                v-for="item in list.childrens"
                v-if="!(item.name === '开场广告' && liveType === 'SERIES')"
                :key="item.id"
                :id="'id'+item.id"
                @mouseover="showBtn(item)"
                @mouseleave="leaveBtn(item)"
                @click="routerLink(item.functionCode,item.id,item)">
                <span>{{item.name}}</span>
                <admin_con
                    v-if="item.functionCode === 'HB' || item.functionCode === 'TL' || item.functionCode === 'HF' || item.functionCode === 'QYHB' || item.functionCode === 'YZT'"
                    :style="{display:item.isFunShow ? '':'none'}"
                    class="icon"
                    @click="openFunModel('修改',item)"
                    type="icon-xiugai"/>
                <admin_con
                    v-if="item.functionCode === 'QYHB' || item.functionCode === 'YZT'"
                    :style="{display:item.isFunShow ? '':'none'}"
                   class="icon2"
                   @click="delMenu(item.id)"
                   type="icon-shanchu"/>
            </a-menu-item>
          </a-menu>
        </div>
      </div>
      <div style="width: 90%;min-height: 670px;background-color: #fff;border-left: 1px solid #d9d9d9">
        <router-view :key="timer"></router-view>
      </div>
    </div>
<!------------------链接-------------------->
    <!--   链接   -->
    <LinkPopup/>
    <!--  栏目排序  -->
    <a-drawer
        title="栏目排序"
        placement="right"
        :visible="visible"
        width="800px"
        :footer="null"
        @close="codeClose"
    >
      <a-alert message="该排序直接影响直播间栏目显示，请注意显示顺序！！" type="info" show-icon />
      <a-table :columns="columns" :data-source="funLists" :pagination="false" :row-key="record => record.id" style="margin-top: 20px">
        <span slot="indexs" slot-scope="text, record,index">
          {{index+1}}
        </span>
        <span slot="name" slot-scope="text, record,index">
          <span :style="{color:record.isShow ? '#333333':'#afadad'}">{{record.name}}</span>
        </span>
        <span slot="functionCode" slot-scope="text, record,index">
          <span :style="{color:record.isShow ? '#333333':'#afadad'}">{{record.functionCode === 'HB'? '介绍':record.functionCode === 'TL'? '讨论':record.functionCode === 'HF'? '回放':record.functionCode === 'QYHB'? '企业海报':record.functionCode === 'YZT'? '云展厅':''}}</span>
        </span>
        <span slot="system" slot-scope="text, record,index">
          <a style="margin-right: 5px" v-show="index !== 0" @click="UpOrDown('up',record.id)">上移</a>
          <a v-show="index !== funLists.length -1" @click="UpOrDown('down',record.id)">下移</a>
        </span>
      </a-table>
    </a-drawer>
    <!--  评论以及加入黑名单对话框  -->
    <a-drawer
        :title="commentTitle"
        placement="right"
        :visible="commentVisible"
        width="800px"
        :footer="null"
        @close="onClose"
    >
      <a-alert type="info" show-icon>
        <span slot="description">
          <div>禁言：将该账号禁言后将无法在本次直播间里进行任何言语评论</div>
          <div style="margin-top: 7px;margin-bottom: 7px">加入黑名单：将该账号加入黑名单后将无法再查看该企业下所有直播会议</div>
          <div style="color: red">以上操作会在直播间对该用户进行提示</div>
        </span>
      </a-alert>
      <div style="padding-bottom: 20px;border-bottom: 1px solid #F5F5F5">
        <div style="margin-top: 20px;font-weight: bold;">账号信息</div>
        <div style="display: flex;justify-content: space-between">
          <div>
            <div style="margin-top: 10px;margin-bottom: 10px">用户名称： 韩笑1</div>
            <div>科室：ICU</div>
          </div>
          <div>
            <div style="margin-top: 10px;margin-bottom: 10px" >用户手机号： 18100000000</div>
            <div>职称：主任医师</div>
          </div>
          <div>
            <div style="margin-top: 10px;margin-bottom: 10px">所在医院： 山东省立医院</div>
            <div>
              <a-popover title="禁言" v-model="postVisible" trigger="click">
                <template slot="content">

                  <div>是否确认禁言？</div>
                  <div style="color: red">禁言后将无法在本直播间进行任何言语评论</div>
                  <a-select
                      placeholder="请选择"
                      v-model="postSpeak"
                      style="width: 120px;margin-top: 10px;margin-bottom: 10px;width: 100%"
                      @change="handleChange">
                    <a-select-option value="1">
                      理由
                    </a-select-option>
                  </a-select>
                  <div style="display: flex;justify-content: right">
                    <a-button @click="closePost" size="small">取 消</a-button>
                    <a-button size="small" type="primary" style="margin-left: 10px">确 认</a-button>
                  </div>
                </template>

                <a>禁言</a>
              </a-popover>


              <a-popover title="加入黑名单" v-model="blacklistVisible" trigger="click" style="margin-left: 5px;margin-right: 5px">
                <template slot="content">
                  <div>是否确认加入黑名单？</div>
                  <div style="color: red">加入黑名单后将无法观看该企业下所有会议</div>
                  <a-select
                      placeholder="请选择"
                      v-model="postSpeak"
                      style="width: 120px;margin-top: 10px;margin-bottom: 10px;width: 100%"
                      @change="handleChange">
                    <a-select-option value="1">
                      理由
                    </a-select-option>
                  </a-select>
                  <div style="display: flex;justify-content: right">
                    <a-button @click="closeBlacklist" size="small">取 消</a-button>
                    <a-button size="small" type="primary" style="margin-left: 10px">确 认</a-button>
                  </div>
                </template>
                <a>加入黑名单</a>
              </a-popover>

              <span style="color: red;">已禁言1次</span>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: space-between;margin-top: 20px">
        <a-popconfirm
            title="是否确认批量删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="delComment"
        >
          <a-button><a-icon style="color: red" type="close-circle" /> 批量删除</a-button>
        </a-popconfirm>
        <a-input-search
            allow-clear
            style="width: 300px"
            placeholder="请输入评论内容"
            enter-button="搜索"
            @search="onSearch"
        />
      </div>
      <a-table
          :pagination="false"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :columns="columnsComment"
          :data-source="dataComment"
          :row-key="record => record.id"
          style="margin-top: 20px">
        <span slot="system" slot-scope="text, record,index">
          <a>删除</a>
        </span>
      </a-table>
    </a-drawer>
    <!--  功能列表修改  -->
    <a-modal
        :title="funTitle"
        :visible="funVisible"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form-model
          ref="funRef"
          :model="funForm"
          :rules="funRules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item  prop="type" label="类型" v-show="funTitle === '新增'">
          <a-select
              :disabled="funTitle === '修改'"
              show-search
              option-filter-prop="children"
              style="width: 300px"
              class="input"
              v-model="funForm.type"
              placeholder="请选择">
            <a-select-option
                v-for="fun in funList"
                :value="fun.code">
              {{fun.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item  prop="name" label="展示名称">
          <a-input v-model="funForm.name" style="width: 300px;" placeholder="请输入"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 修改会议   -->
    <a-drawer
        @close="add_cancel"
        :width="660"
        :body-style="{ paddingBottom: '80px' }"
        :visible="add_visible"
        :title="drawerTitle">
      <a-form-model
          ref="content_list_Form_add"
          :model="add_Form"
          :rules="liveType === 'SINGLE' ? addFormRules : addFormRulesX"
          :label-col="labelColAdd"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item  prop="name" label="标题">
          <a-input v-model="add_Form.name" style="width: 300px;" placeholder="请输入"/>
        </a-form-model-item>

        <a-form-model-item  prop="time" label="会议时间">
          <a-input v-model="add_Form.time" style="width: 300px;" placeholder="例：6月21日 08:30～11:00"/>
        </a-form-model-item>

        <a-form-model-item  prop="startTime" label="开始时间">
          <a-date-picker
              style="width: 300px"
              placeholder="请选择"
              @change="StartChange"
              v-model="add_Form.startTime"
              format="YYYY-MM-DD HH:mm:ss"
              :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
          />
        </a-form-model-item>

        <a-form-model-item v-if="liveType === 'SINGLE'"  prop="columId" label="会议分类" style="width: 600px">
          <a-select
              show-search
              option-filter-prop="children"
              style="width: 300px;float: left"
              class="input"
              v-model="add_Form.columId"
              placeholder="请选择">
            <a-select-option
                v-for="lives in liveClassifyList"
                :value="lives.id">
              {{lives.title}}
            </a-select-option>
          </a-select>
          <!--              <div>-->
          <a-icon @click="liveClassifyRow" class="iconStY" type="redo" />
          <a-icon @click="loadList" class="iconStY" type="plus-square" />
          <!--              </div>-->
        </a-form-model-item>

        <a-form-model-item label="详情封面图" prop="relayImg">
          <div style="position: relative;width: 288px;">
            <div class="upImgModel" @click="openImgModel('relayImg')">
              <div class="content" v-if="!add_Form.relayImg">
                <div class="addModel">+</div>
                <div>
                  上传
                </div>
              </div>
              <div>
                <img style="width: 280px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;margin-left: 3px"
                     :src="add_Form.relayImg" alt="">
              </div>
            </div>
            <a-icon v-if="add_Form.relayImg"
                    @click="()=>{add_Form.relayImg = ''}"
                    class="icon" type="close-circle"/>
          </div>
<!--          <div class="surfacePlot" style="position: relative">-->
<!--            <div style="position: absolute;width: 100%;text-align: center" v-show="!add_Form.relayImg">-->
<!--              <div style="font-size: 40px;color: #929292;">+</div>-->
<!--              <div style="font-size: 20px;color: #929292">上传</div>-->
<!--            </div>-->
<!--            <input-->
<!--                id="surfacePlot"-->
<!--                ref="surfacePlot"-->
<!--                style="width: 100%;-->
<!--                  height: 100%;opacity: 0;-->
<!--                  z-index: 999999;-->
<!--                  position: absolute;top: 0"-->
<!--                accept="image/*"-->
<!--                type="file"-->
<!--                @change="surfacePlotBtn" />-->
<!--            <div class="uploadImg_img">-->
<!--              <img-->
<!--                  v-if="add_Form.relayImg"-->
<!--                  alt=""-->
<!--                  style="width: 98%;height:98%;border-radius: 5px;margin: auto;margin-top: 2px;background-color: #fff;"-->
<!--                  :src="add_Form.relayImg"-->
<!--              />-->
<!--            </div>-->
<!--            <div style="position: absolute;top: -8px;right: 10px;z-index: 99999999">-->
<!--              <a-icon v-show="add_Form.relayImg[0]" @click="()=>{add_Form.relayImg = ''}" style="color: #f82c2c;cursor: pointer;" type="close-circle" />-->
<!--            </div>-->
<!--          </div>-->
          <div style="color: #a1a1a1">注:尺寸 640*360，大小不超过500kb</div>
        </a-form-model-item>

        <a-form-model-item label="介绍海报">
          <div style="position: relative;width: 288px;">
            <div class="upImgModel" @click="openImgModel('homeCoverImage')">
              <div class="content" v-if="!add_Form.homeCoverImage">
                <div class="addModel">+</div>
                <div>
                  上传
                </div>
              </div>
              <div>
                <img style="width: 280px;border-radius: 10px;margin-top: 1%;margin-bottom: 1%;margin-left: 3px"
                     :src="add_Form.homeCoverImage" alt="">
              </div>
            </div>
            <a-icon v-if="add_Form.homeCoverImage"
                    @click="()=>{add_Form.homeCoverImage = ''}"
                    class="icon" type="close-circle"/>
          </div>
<!--          <div class="bgImg" style="position: relative">-->
<!--            <div style="position: absolute;width: 100%;text-align: center" v-show="!add_Form.homeCoverImage">-->
<!--              <div style="font-size: 40px;color: #929292;margin-bottom: 10px;margin-top: 30px">+</div>-->
<!--              <div style="font-size: 20px;color: #929292">上传</div>-->
<!--            </div>-->
<!--            <input-->
<!--                id="add_back_id"-->
<!--                ref="add_back_ref"-->
<!--                style="width: 100%;height: 100%;-->
<!--                  z-index: 999999;-->
<!--                  opacity: 0;-->
<!--                  position: absolute;top: 0"-->
<!--                accept="image/*"-->
<!--                type="file"-->
<!--                @change="add_back" />-->
<!--            <div class="uploadImg_img">-->
<!--              <img-->
<!--                  v-if="add_Form.homeCoverImage"-->
<!--                  alt=""-->
<!--                  style="width: 98%;height:auto;border-radius: 5px;margin: auto;margin-top: 2px;background-color: #fff;"-->
<!--                  :src="add_Form.homeCoverImage"-->
<!--              />-->
<!--            </div>-->
<!--            <div style="position: absolute;top: -8px;right: 10px;z-index: 99999999">-->
<!--              <a-icon v-show="add_Form.homeCoverImage" @click="()=>{add_Form.homeCoverImage = ''}" style="color: #f82c2c;cursor: pointer;" type="close-circle" />-->
<!--            </div>-->
<!--          </div>-->
          <div style="color: #a1a1a1">注:尺寸 宽640，大小不超过1M</div>
        </a-form-model-item>
      </a-form-model>
      <div :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }">
        <a-button  @click="add_cancel">
          取消
        </a-button>
        <a-button style="margin-left: 10px" type="primary" @click="add_content_list_Ok">
          确认
        </a-button>
      </div>
    </a-drawer>
    <!-- 新增对话框 -->
    <a-modal
        title="新增"
        :visible="visibleClass"
        @ok="handleOk_class"
        @cancel="handleCancel_class"
        style="width:200px;height: 130px;"
    >
      <a-form-model :model="form"  :rules="addRules" ref="ruleForm_add" style="display:flex">
        <a-form-model-item label="分类标题" prop="name" style="display:flex">
          <a-input  placeholder="请输入"   v-model="form.name"  style="display:flex;margin-top:5px;width:220px"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!------------------图片对话框----------------------->
    <pictureDialog
        :visible="visiblePic"
        v-on:closeMain="closeMain"
        v-on:checkList="checkList">
    </pictureDialog>
  </div>
</template>

<script>
import LinkPopup from "@/components/LiveRoomFunModule/LinkPopup";
import {admin_icon} from "@/utils/myIcon";
import {
  delStreamFunc,
  liveRoom, liveMettingSpecialsPut,
  postStreamFunc,
  putStreamFunc,
  selectAllDataStreamFunction,
  streamFunc, streamFuncUpOrDown, updLiveStream, getLiveRoom, putLiveRoom
} from "@/service/liveManage";
import {liveClassify} from "@/service/MedicalConference";
import {update} from "@/utils/update";
import moment from "moment";
import {AddManagement} from "@/service/columnmanageapi";
import pictureDialog from "@/components/Material/pictureDialog";
export default {
name: "LiveRoom",
  components:{
    admin_con: admin_icon,
    LinkPopup,
    pictureDialog
  },
  data(){
    return{
      visiblePic:false,
      openModelType:null,
      timer:null,
      form:{},
      addRules:{
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },
      visibleClass:false,
      liveClassifyList:[],
      collapsed:false,
      isShowBtn:false,
      selectedKeys:[],
      openKeys:[1],
      funLists:[],
      pathList:[
        {
          id:1,
          title:'直播间设置',
          path:'',
          childrens:[],
        },
        {
          id:2,
          title:'高级功能设置',
          path:'',
          childrens:[
            {
              id:212121,
              functionCode:'XFGN',
              name:'悬浮功能',
            },
          ],
        },
        {
          id:3,
          title:'广告通知',
          path:'',
          childrens:[
            {
              id:313131,
              functionCode:'KCGG',
              name:'开场广告',
            },{
              id:323232,
              functionCode:'TZGG',
              name:'通知公告',
            },
          ],
        },
      ],
      visible:false,
      columns:[
        {
          title:'排序',
          scopedSlots: { customRender: 'indexs' },
        },{
          title:'栏目名称',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' },
        },{
          title:'栏目类型',
          dataIndex: 'functionCode',
          key: 'functionCode',
          scopedSlots: { customRender: 'functionCode' },
        },{
          title:'操作',
          scopedSlots: { customRender: 'system' },
        },
      ],
      columnsComment:[
          {
          title:'评论内容',
          dataIndex: 'content',
          key: 'content',
        },{
          title:'评论时间',
          dataIndex: 'time',
          key: 'time',
        },{
          title:'操作',
          scopedSlots: { customRender: 'system' },
        },
      ],
      dataComment:[
        {
          id:1,
          content:'挺好的挺好的',
          time:'2023-09-08 11:00'
        }, {
          id:2,
          content:'挺好的挺好的',
          time:'2023-09-08 11:00'
        },
      ],
      commentTitle:'韩笑',
      commentVisible:false,
      selectedRowKeys: [],
      postSpeak:undefined,
      postVisible:false,
      blacklistVisible:false,
      funVisible:false,
      funTitle:'新增',
      funRules:{
        type: [{ required: true, message: '请选择', trigger: 'change' }],
        name: [
            { required: true, message: '请输入', trigger: 'blur' },
            { min: 1, max: 10, message: '栏目名称最多10个字符', trigger: 'blur' },],
      },
      funForm:{
        name:'',
        type:undefined,
      },
      labelCol: { span: 4},
      wrapperCol: { span: 14 },
      funList:[ ],
      meetingCode:null,
      liveType:null,
      liveId:null,
      liveContent:{},
      add_visible:false,
      add_Form:{
        name:'',
        time:'',
        startTime:'',
        columId:undefined,
        homeCoverImage:'',
        relayImg:'',
      },
      drawerTitle:'编辑',
      addFormRules:{ //普通小会
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        time: [{ required: true, message: '请选择', trigger: 'change' }],
        startTime: [{ required: true, message: '请选择', trigger: 'change' }],
        columId: [{ required: true, message: '请选择', trigger: 'change' }],
        relayImg: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      addFormRulesX:{ //系列会
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        time: [{ required: true, message: '请选择', trigger: 'change' }],
        startTime: [{ required: true, message: '请选择', trigger: 'change' }],
        // columId: [{ required: true, message: '请选择', trigger: 'change' }],
        relayImg: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      labelColAdd: { span: 6},
    }
  },
  created() {
    // this.$nextTick(()=>{
    //   document.documentElement.scrollTop = 0;
    // })
    this.meetingCode = this.$route.query.meetingCode;
    this.liveType = this.$route.query.type;
    this.liveId = this.$route.query.id;
    this.liveClassifyRow()
    this.getLiveRooms();
    this.getFun();
  },
  mounted() {
    // document.getElementsByClassName("LiveRooms")[0].scrollTop = 0; // 设置滚动条位置;
  },
  methods:{
    openImgModel(type){
      this.visiblePic = true;
      this.openModelType = type;
    },
    closeMain(val) {
      this.visiblePic = val;
    },
    checkList(val) {
      this.picList = val[0].url;
      let type = this.openModelType;
      if(type === 'relayImg'){ // 详情封面图
        this.add_Form.relayImg = this.picList;
      }else if(type === 'homeCoverImage'){ //背景图
        this.add_Form.homeCoverImage = this.picList;
      }
    },
    moment,
    add_content_list_Ok() {
      this.$refs.content_list_Form_add.validate( valid => {
        if (valid) {
          let form = this.add_Form;
          let dataRest = {};
          if(this.liveType === 'SINGLE'){ //普通小会
            dataRest = {
              "title":form.name,
              "displayTime":form.time,
              "sortTime":form.startTime,
              "detailCoverUrl":form.relayImg,
              "descImageUrl":form.homeCoverImage,
              "columnId":form.columId,
              "type":"SINGLE"
            }
            this.putLive(this.meetingCode,dataRest);
          }else if(this.liveType === 'SERIES'){ //系列会
            dataRest = {
              "title":form.name,
              "showTime":form.time,
              "live_start_time":form.startTime,
              "coverUrl":form.relayImg,
              "descImageUrl":form.homeCoverImage,
              "id":this.liveId
            }
            this.putLiveRooms(dataRest);
          }
          this.add_cancel()
        } else {
          return false;
        }
      });
    },
    //修改直播间列表-系列会
    async putLiveRooms(data) {
      const response = await putLiveRoom(data)
      if(response.code === 0){
        this.$message.success("修改成功~");
        //判断当前路径是否为HB--/liveRoom/introduce 是则刷新数据
        if(this.$route.path === '/liveRoom/introduce'){
          this.timer = new Date().getTime(); //刷新子界面
        }
        await this.getLiveRooms('edit');
      }else {
        this.$message.warning(response.message);
      }
    },
    //修改直播会议列表-普通小会
    async putLive(meetingCode,data) {
      const response = await liveMettingSpecialsPut(meetingCode,data)
      if(response.code === 0){
        this.$message.success("修改成功~");
        //判断当前路径是否为HB--/liveRoom/introduce 是则刷新数据
        if(this.$route.path === '/liveRoom/introduce'){
          this.timer = new Date().getTime(); //刷新子界面
        }
        await this.getLiveRooms('edit');
      }else {
        this.$message.warning(response.message)
      }
    },
    async add_back() {
      let form = this.add_Form;
      let _this = this
      let inputDOM = this.$refs.add_back_ref.files[0];
      //判断图片大小
      if (inputDOM.size < 1048576) {
        //判断图片尺寸
        if (inputDOM) {
          var reader = new FileReader()
          reader.onload = function (event) {
            var base64 = event.target.result
            var img = document.createElement('img')
            img.src = base64
            img.onload = async function () {
              if (img.width <= 640) {
                const image = await update(inputDOM)
                if (image.code === 0) {
                  _this.add_Form.homeCoverImage = image.data.url
                  _this.$message.success("上传成功")
                } else {
                  _this.$message.warning("上传失败")
                }
              } else {
                _this.$message.warning("注:宽度不能超过540px !")
              }
            }
          }
          reader.readAsDataURL(inputDOM)
        }
      } else {
        this.$message.warning("请上传小于1M的图片！")
      }
      document.getElementById('add_back_id').value = null
    },
    async surfacePlotBtn() {
      let inputDOM = this.$refs.surfacePlot.files[0];
      //判断图片大小
      if (inputDOM.size < 512000) {
        const image = await update(inputDOM)
        if (image.code === 0) {
          this.add_Form.relayImg = image.data.url
          this.$message.success("上传成功")
        } else {
          this.$message.warning("上传失败")
        }
      } else {
        this.$message.warning("请上传小于500kb的图片！")
      }
      document.getElementById('surfacePlot').value = null
    },
    loadList(){
      this.visibleClass = true;
    },
    handleOk_class(){
      this.$refs.ruleForm_add.validate(valid=> {
        if (valid) {
          let data = {
            title: this.form.name,
            type:'meeting'
          }
          this.postClass(data)
          this.form.name = '';
          this.visibleClass = false;
        }
      })
    },
    async postClass(data) {
      const response = await AddManagement(data)
      if (response.code === 200) {
        this.$message.success("新增成功！")
      } else {
        this.$message.warning(response.message)
      }
    },
    handleCancel_class(){
      this.visibleClass = false;
    },
    async liveClassifyRow() {
      let data = {
        pageNo:1,
        pageSize: 9999999,
        type:'meeting'
      }
      const response = await liveClassify(data)
      if(response.code === 0){
        this.liveClassifyList = response.data;
      }else {
        this.$message.warning(response.message)
      }
      // this.spinning = false
    },
    StartChange(date, dateString){
      this.add_Form.startTime = dateString;
    },
    //获取直播间列表
    async getLiveRooms(type) {
      let data = {
        page_no:1,
        page_size:1
      }
      let response;
      if(this.liveType === 'SINGLE') { //普通小会

        response = await getLiveRoom(this.meetingCode,data);

      }else if(this.liveType === 'SERIES') { //系列会

        response = await liveRoom(this.liveId,data);

      }

      if(response.code === 0){
        this.liveContent = response.data.rows[0];
        await this.$store.dispatch('streamId', this.liveContent.id);
        if(type !== 'edit'){
          await this.getStreamFunc(response.data.rows[0].id);
        }
      }
    },

    // 获取直播间设置功能列表
    async getStreamFunc(streamId) {
      const response = await streamFunc(streamId)
      if(response.code === 0){
        this.pathList[0].childrens = response.data;
        //系列会进入不显示开场广告

        this.selectedKeys[0] = response.data[0].id;

        this.$store.dispatch('funId', response.data[0].id);
        this.$store.dispatch('funList', response.data[0]);


          //根据第一个code值判断跳转路径
          let path = '';
          switch (response.data[0].functionCode){
            case 'HB': //介绍
              path = '/liveRoom/introduce';
              break;
            case 'TL': //讨论
              path = '/liveRoom/discussion';
              break;
            case 'HF': //回放
              path = '/liveRoom/playback';
              break;
            case 'QYHB': //企业海报
              path = '/liveRoom/corporatePoster';
              break;
            case 'YZT': //云展厅
              path = '/liveRoom/exhibitionHall';
              break;
          }

          await this.$router.push({
            path: path,
            query: {id: this.liveId, meetingCode: this.meetingCode, type: this.liveType}
          })
      }
    },
    // 获取直播间设置功能列表
    // async getStreamFuncModel(streamId) {
    //   const response = await streamFunc(streamId)
    //   if(response.code === 0) {
    //
    //   }
    // },
    codeClose(){
      this.visible = false;
    },
    //获取查询直播间设置功能类型字典
    async getFun() {
      const response = await selectAllDataStreamFunction()
      if (response.code === 0) {
        this.funList = response.data;
      }else {
        this.$message.warning(response.message)
      }
    },
    handleCancel(){
      this.funVisible = false;
      this.$refs.funRef.resetFields();
    },
    handleOk(){
      this.$refs.funRef.validate(valid => {
        if (valid) {
          let form = this.funForm
          if(this.funTitle === '新增'){
            let data = {
              "streamId": this.liveContent.id, //直播间id
              "functionCode": form.type, //功能code值
              "name": form.name //功能名称
            }
            this.postStreamFuncs(data);
          }else if(this.funTitle === '修改'){
            let data = {
              "id":this.selectedKeys[0],
              "streamId": this.liveContent.id, //直播间id
              "functionCode": form.type, //功能code值
              "name": form.name //功能名称
            }
            this.putStreamFuncs(data);
          }
          this.handleCancel();
        } else {
          return false;
        }
      });
    },
    // 新增直播间设置功能列表
    async postStreamFuncs(data) {
      const response = await postStreamFunc(data)
      if(response.code === 200){
        this.$message.success("新增成功！");
        await this.getStreamFunc(this.liveContent.id)
      }else {
        this.$message.warning(response.message)
      }
    },
    // 修改直播间设置功能列表
    async putStreamFuncs(data) {
      const response = await putStreamFunc(data)
      if(response.code === 200){
        this.$message.success("修改成功！");
        await this.getStreamFunc(this.liveContent.id);
      }else {
        this.$message.warning(response.message);
      }
    },
    // 获取直播间设置功能列表
    async getFunList(streamId) {
      const response = await streamFunc(streamId)
      if (response.code === 0) {
        this.funLists = response.data;
      }else {
        this.$message.warning(response.message)
      }
    },
    // 直播间功能设置栏目上移下移
    async UpOrDown(type,id) {
      const response = await streamFuncUpOrDown(type,id);
      if(response.code === 0){
        this.$message.success("操作成功！");
        await this.getFunList(this.liveContent.id);
        await this.getStreamFunc(this.liveContent.id);
      }else {
        this.$message.warning(response.message);
      }
    },
    async editLive(){
      await this.getLiveRooms('edit');
      let row = this.liveContent;
      this.add_Form = {
        name:row.title,
        time:row.showTime,
        startTime:row.live_start_time,
        columId:row.liveTypeId,
        relayImg:row.coverUrl,
        homeCoverImage:row.descImageUrl,
      }
      this.add_visible = true;
    },
    // 直播间功能设置栏目上移下移
    async updLiveRoom(data) {
      const response = await updLiveStream(data);
      if(response.code === 0){
        this.$message.success("编辑成功！");
        await this.getLiveRooms();
      }else {
        this.$message.warning(response.message);
      }
    },
    handleChange(){

    },
    onSearch(){

    },
    delComment(){

    },
    openComment(){
      this.commentVisible = true;
    },
    closePost(){
      this.postVisible = false;
    },
    closeBlacklist(){
      this.blacklistVisible = false;
    },
    onSelectChange(selectedRowKeys){
      //选择的列表的id
      this.selectedRowKeys = selectedRowKeys;
    },
    returnBtn(){
      if(this.liveType === 'SINGLE') { //普通小会
        this.$router.push({path:'/liveStreaming'});
      }else if(this.liveType === 'SERIES'){ //系列会
        this.$router.push({path:'/liveConferenceSeries'});
      }

      this.$store.dispatch('isShowNav',true); //恢复导航栏
    },
    // change_select(){
    //   let path = this.$route.path;
    //   for (const i in this.pathList){
    //     for (const j in this.pathList[i].childrens){
    //       if(path === this.pathList[i].childrens[j].title){
    //         this.selectedKeys = [this.pathList[i].childrens[j].id]
    //       }
    //     }
    //   }
    // },
    openLink(){
      this.$store.dispatch('isShowLink',true);
    },
    openCode(){
      this.visible = true;
      this.getFunList(this.liveContent.id)
    },
    onClose(){
      this.commentVisible = false;
      this.modelInput = '';
    },
    // 删除直播间设置功能列表
    async delMenu(id) {
      const response = await delStreamFunc(id)
      if(response.code === 200){
        this.$message.success("删除成功！");
        await this.getStreamFunc(this.liveContent.id);
      }else {
        this.$message.warning(response.message);
      }
    },
    routerLink(funCode,funId,row){
      // console.log(funCode,funId,row)
      this.selectedKeys[0] = funId;
      //功能模块id
      this.$store.dispatch('funId',funId);
      this.$store.dispatch('funList',row);

      //根据code值判断跳转路径
      let path = '';
      switch (funCode){
        case 'HB': //介绍
          path = '/liveRoom/introduce';
          break;
        case 'TL': //讨论
          path = '/liveRoom/discussion';
          break;
        case 'HF': //回放
          path = '/liveRoom/playback';
          break;
        case 'QYHB': //企业海报
          path = '/liveRoom/corporatePoster';
          break;
        case 'YZT': //云展厅
          path = '/liveRoom/exhibitionHall';
          break;
        case 'XFGN': //悬浮功能
          path = '/liveRoom/suspensionFunction';
          break;
        case 'KCGG': //开场广告
          path = '/liveRoom/openingAdvertisement';
          break;
        case 'TZGG': //通知公告
          path = '/liveRoom/notificationAnnouncement';
          break;
      }
      this.$router.push({path:path,query:{id:this.liveId,meetingCode:this.meetingCode,type:this.liveType}})
      if(funCode === 'QYHB' || funCode === 'YZT'){
        this.timer = new Date().getTime(); //刷新子界面
      }
    },
    showBtn(item){
      this.$set(item,'isFunShow',true);
    },
    leaveBtn(item){
      this.$set(item,'isFunShow',false);
    },
    openFunModel(type,item){
      if(type === '新增'){
        this.funTitle = '新增';
        this.funForm = {};
      }else if(type === '修改'){
        this.funTitle = '修改';
        let form = this.funForm;
        form.type = item.functionCode;
        form.name = item.name;
      }
      this.funVisible = true;
    },
    add_cancel(){
      //  关闭对话框
      this.add_visible = false;
      this.add_Form = {
        name:'',
        time:'',
        startTime:'',
        columId:undefined,
        homeCoverImage:'',
        relayImg:'',
      };
      this.listId = '';
      // this.getStreamFunc(this.liveContent.id)
    },
  },
}
</script>

<style scoped lang="scss">
.LiveRooms{
  width: 100%;
  border: none;
  position:relative;
  //top: -71px;
  border-top: 1px solid #F5F5F5;
  border-left: 1px solid #F5F5F5;
  .liveRntroduce{
    width: 98%;
    min-height: 200px;
    margin: auto;
    display: flex;
    img{
      width: 320px;
      margin: 15px 30px 10px 20px;
    }
    .topRight{
      width: 55%;margin-top: 30px;
      .title{
        font-weight: bold;margin-right: 10px;
      }
      .tag{
        height: 24px;margin-top: 3px;
      }
      .content{
        width: 50%;
        .font{
          margin-top: 10px;
        }
      }
    }
    .topBtn{
      width: 30%;margin-top: 30px;text-align: right;
    }
  }
  .liveRntroduce-content{
    border: 10px solid #EDEFF2;width: 100%;display: flex;
    .menu{
      width: 100%;height: 50px;display: flex;line-height: 50px;
      .add{
      margin-left: 40%;font-size: 30px;z-index: 999;line-height: 40px;
      }
    }
    .icon{
      margin-left: 10px;z-index: 9999;
    }
    .icon2{
      z-index: 9999;
    }
  }
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 300px;
  height: 169px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
}
.bgImg{
  border: #DDDDDD 2px dashed;
  width: 213px;
  min-height: 213px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
}
.surfacePlot{
  border: #DDDDDD 2px dashed;
  width: 213px;
  height: 120px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px;
}
.uploadImg_img{
  position: absolute;
  top: 0;
  width: 100%;height:100%;
}
.iconStY{
  color:#45a5e6;
  font-size: 20px;
  margin-left: 5px;
  margin-top:3px;float: left;
}
.upImgModel{
  width: 288px;
  min-height: 162px;
  text-align: center;
  background-color: #fcfcfc;
  border: #DDDDDD 2px dashed;
  border-radius: 10px;
  .content{
    font-size: 20px;
    text-align: center;
    width: 100%;
    margin-top: 50px;
    color: #929292;
    .addModel{
      font-size: 30px;
    }
  }
}
.icon{
  position: absolute;
  color: red;
  right: 8px;
  top:8px;
  z-index: 99999999999999;
}
</style>
